/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a",
    p: "p",
    section: "section",
    h2: "h2",
    div: "div",
    em: "em",
    code: "code",
    pre: "pre",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#about-and-why-docker"
  }, "About and Why Docker")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#prerequisites"
  }, "Prerequisites")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#yml-rundown"
  }, "YML Rundown")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#start-developing"
  }, "Start Developing!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#additional-resources"
  }, "Additional Resources")), "\n"), "\n", React.createElement(_components.p, null, "For some developers, setting up infrastructure is the last thing a developer wants to do. WordPress, in particular, requires a MySQL database and a PHP environment, the old reliable LAMP stack, to be setup before developers can begin the famous 5 minute WordPress install. While there are solutions like ", React.createElement(_components.a, {
    href: "https://www.mamp.info",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "MAMP"), " or ", React.createElement(_components.a, {
    href: "https://localwp.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Local"), " which can handle the grunt work, I prefer ", React.createElement(_components.a, {
    href: "https://github.com/docker/awesome-compose/tree/master/official-documentation-samples/wordpress",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Docker"), " for setting up a local development environment for making WordPress plugins."), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "about-and-why-docker",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#about-and-why-docker",
    "aria-label": "about and why docker permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "About and Why Docker"), React.createElement(_components.p, null, "Docker is a suite of technologies which allows developers to ", React.createElement(_components.em, null, "\"easily\""), " create application environments via ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/OS-level_virtualization",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "containers"), ". These containers solve the age-old software developer colloquy, ", React.createElement(_components.em, null, "\"It worked on my machine.\""), " In this guide, I'll be using ", React.createElement(_components.a, {
    href: "https://docs.docker.com/compose/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Docker Compose"), ", which allows developers to describe the application environment via YAML and then start and stop services via CLI."), React.createElement(_components.p, null, "Comparing Docker to other local development WordPress environment technologies, I believe there is a higher learning curve with Docker.\nHowever, I think the advantages exceed the disadvantages, particularly in terms of automation and consistency of the environment.")), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "prerequisites",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#prerequisites",
    "aria-label": "prerequisites permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Prerequisites"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Install ", React.createElement(_components.a, {
    href: "https://www.docker.com/products/docker-desktop/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Docker Desktop")), "\n", React.createElement(_components.li, null, "Create a ", React.createElement(_components.code, null, "docker-compose.yml"), " file in the project root"), "\n", React.createElement(_components.li, null, "(Optional) A plugin directory to serve as the project root"), "\n")), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "yml-rundown",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#yml-rundown",
    "aria-label": "yml rundown permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "YML Rundown"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-yaml"
  }, "services:\n  db:\n    image: mysql:latest\n    command: '--default-authentication-plugin=mysql_native_password'\n    volumes:\n      - db_data:/var/lib/mysql\n    restart: always\n    environment:\n      - MYSQL_ROOT_PASSWORD=root\n      - MYSQL_DATABASE=wordpress\n      - MYSQL_USER=wordpress\n      - MYSQL_PASSWORD=wordpress\n    expose:\n      - 3306\n      - 33060\n  wordpress:\n    image: wordpress:latest\n    ports:\n      - 8080:80\n    restart: always\n    environment:\n      - WORDPRESS_DB_HOST=db\n      - WORDPRESS_DB_USER=wordpress\n      - WORDPRESS_DB_PASSWORD=wordpress\n      - WORDPRESS_DB_NAME=wordpress\n    volumes:\n      - wordpress_instance:/var/www/html\n      - .:/var/www/html/wp-content/plugins/[plugin-name]\nvolumes:\n  db_data:\n  wordpress_instance:\n")), React.createElement(_components.p, null, "Copy the above YML configuration to the ", React.createElement(_components.code, null, "docker-compose.yml"), " file. Here's a rundown on what's going on:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "2 services: ", React.createElement(_components.code, null, "db"), " and ", React.createElement(_components.code, null, "wordpress"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "both pulling from the latest images (", React.createElement(_components.a, {
    href: "https://hub.docker.com/_/mysql",
    target: "_blank",
    rel: "noopener noreferrer"
  }, React.createElement(_components.code, null, "mysql")), " and ", React.createElement(_components.a, {
    href: "https://hub.docker.com/_/wordpress",
    target: "_blank",
    rel: "noopener noreferrer"
  }, React.createElement(_components.code, null, "wordpress")), ")"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "db"), " service is a ", React.createElement(_components.code, null, "mysql"), " database with the ports ", React.createElement(_components.code, null, "3306"), " and ", React.createElement(_components.code, null, "33060"), " expose for ", React.createElement(_components.code, null, "wordpress"), " service to connect to"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "wordpress"), " service has the container port ", React.createElement(_components.code, null, "80"), " to the host machine port ", React.createElement(_components.code, null, "8080"), " which allows the local development url ", React.createElement(_components.code, null, "http://localhost:8080/"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "NOTE:"), " If the ", React.createElement(_components.code, null, "docker-compose.yml"), " was in a plugin project directory, be sure to rename the volume binding ", React.createElement(_components.code, null, ".:/var/www/html/wp-content/plugins/[plugin-name]"), " to correct plugin name in slug case"), "\n"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "2 persistent volumes: ", React.createElement(_components.code, null, "db_data"), " and ", React.createElement(_components.code, null, "wordpress_instance"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "both are mapped in each respective service"), "\n"), "\n"), "\n")), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "start-developing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#start-developing",
    "aria-label": "start developing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Start Developing!"), React.createElement(_components.p, null, "From the project root, run:"), React.createElement(_components.pre, null, React.createElement(_components.code, null, "docker compose up -d\n")), React.createElement(_components.p, null, "This command will build the services as describe in the ", React.createElement(_components.code, null, "docker-compose.yml"), ". The flag ", React.createElement(_components.code, null, "-d"), " will run the containers in the background."), React.createElement(_components.p, null, "Congratulations! A local WordPress instance should be accessible at ", React.createElement(_components.code, null, "http://localhost:8080/"), " and WordPress '5-min install' should now be accessible! After the install, plugin development can commence!"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " If the ", React.createElement(_components.code, null, "docker-compose.yml"), " was in a plugin project directory, then the plugin can be activated!"), React.createElement(_components.p, null, "To stop the WordPress instance container, run:"), React.createElement(_components.pre, null, React.createElement(_components.code, null, "docker compose down\n")), React.createElement(_components.p, null, "Alternatively, to delete the WordPress data, run:"), React.createElement(_components.pre, null, React.createElement(_components.code, null, "docker compose down -v\n")), React.createElement(_components.p, null, "The flag ", React.createElement(_components.code, null, "-v"), " will remove the persistent volumes.")), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "additional-resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#additional-resources",
    "aria-label": "additional resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Additional Resources"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/docker/awesome-compose/tree/master/wordpress-mysql",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "https://github.com/docker/awesome-compose/tree/master/wordpress-mysql")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.docker.com/compose/compose-file/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "https://docs.docker.com/compose/compose-file/")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.docker.com/compose/reference/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "https://docs.docker.com/compose/reference/")), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
